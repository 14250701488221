.menu {
  $root: &;
  position: fixed;
  height: 100vh;
  width: 100%;
  background: rgba(0, 0, 0, 0.97);
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  padding-top: 86px; // header height
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  transform: translateX(100%);
  transition: transform 500ms ease, opacity 500ms ease;
  opacity: 0;

  &--visible {
    transform: translateX(0);
    opacity: 1;

    #{$root}__logo {
      transform: translateX(50%) scaleX(1);
      transition-delay: 1s;
    }
  }

  &__logo {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    transform: translateX(-350%) scaleX(-1);
    transition: transform 2s ease;
    transition-delay: 0s;
    opacity: 0.05;
    pointer-events: none;
  }

  &__list {
    text-align: right;
    font-size: 21px;
    color: #fff;
    border-right: 1px solid #8a8a8a;
    padding: 5px 15px 5px 0;

    @media (min-width: 767px) {
      margin-top: 0;
    }
  }

  &__item {
    margin-bottom: 15px;
    line-height: 29px;
    cursor: pointer;
    transition: transform 250ms ease;

    a {
      color: inherit;
      text-decoration: none;
    }

    &:hover {
      transform: translateX(-7px);
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__contact {
    margin-top: 97px;
    display: block;

    @media (min-width: 767px) {
      display: none;
    }
  }
}
