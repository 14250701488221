.terra-wallet-to-eth {
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 20px;

  &__message {
    width: 100%;
    max-width: 650px;
    .title {
      font-size: 28px;
    }
  }

  &__eth-address {
    margin-top: 25px;
    width: 100%;
    max-width: 650px;
    &-text {
      font-size: 12px;
      padding: 0 10px 10px 0;
      display: inline;
    }
    &-error {
      color: red;
      font-size: 12px;
      padding: 10px 0 0 0;
    }
    &-value {
      color: #66cc9a;
      font-size: 14px;
      font-weight: bold;
      padding: 0 0 10px 0;
      display: inline;
    }
    &-input {
      margin-top: 10px;
    }
  }

  &__button {
    margin-top: 25px;
    background-color: red;
  }

  &__fee-info {
    margin-top: 10px;
    text-align: right;
    font-size: 12px;
  }

  &__info {
    color: #66cc9a;
    font-size: 14px;
    font-family: Trivial, sans-serif;
    margin: 10px 0;

    span {
      color: #fff;
    }
  }

  &__none-message {
    width: 100%;
    text-align: center;
  }

  &__timer {
    justify-content: left;

    .timer__digit,
    .timer__colon {
      color: #fff;
      font-size: 14px;
      line-height: 18px;
      font-family: "Poppins";
    }
    .timer__description {
      display: none;
    }
  }
}
