.happy-hour {
  $root: &;
  &__full-mode {
    text-align: left;
    filter: drop-shadow(2px 2px 3px #000);
  }
  display: block;
  text-align: center;

  &__timer {
    .timer__digit,
    .timer__colon {
      font-size: 26px;
      line-height: 26px;
    }
  }

  &__apr {
    display: flex;
    align-items: center;
  }

  &__token-logo {
    margin-left: 4px;
    width: 40px;
  }

  &__value {
    color: #66cc9a;
    font-size: 21px;
  }

  &__detail {
    display: flex;
    color: #fff;
  }

  &__details {
    border-top: 1px solid #40444a;
    border-bottom: 1px solid #40444a;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 10px;

    &--highlighted {
      padding: 10px 0;

      .text {
        font-family: Trivial, sans-serif;
      }

      #{$root}__detail {
        color: #ffffff;
        font-size: 14px;
      }

      #{$root}__value {
        color: #66cc9a;
        font-size: 21px;
      }
    }
  }

  &__details-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;

    &:last-child {
      margin: 0;
    }
  }

  &__title-container-full-mode {
    display: flex;
    align-items: center;
    justify-content: left;
    margin-bottom: 30px;
  }

  &__title-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
  }

  &__container-confirmation-item {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }

  &__confirmation-text {
    font-size: 13px;
    line-height: 1.5;
    text-align: left;
    font-family: "Poppins", sans-serif;
    color: #fff;
    cursor: pointer;

    a {
      color: white;
    }
  }

  &__full-mode-title.text {
    font-size: 36px;
    font-weight: bold;
  }

  &__full-mode-title-live.text {
    margin-left: 6px;
    font-size: 36px;
    font-weight: bold;
    color: #66cc9a;
  }

  &__title.text {
    font-size: 18px;
    font-weight: bold;
  }

  &__title-live.text {
    margin-left: 6px;
    font-size: 18px;
    font-weight: bold;
    color: #66cc9a;
  }

  &__full-mode-icon {
    fill: #66cc9a;
    width: 36px;
    height: 23px;
    min-width: 36px;
    min-height: 23px;
    margin-left: 10px;
  }

  &__icon {
    fill: #66cc9a;
    width: 26px;
    height: 23px;
    min-width: 26px;
    min-height: 23px;
    margin-left: 10px;
  }

  &__gradient-container {
    position: relative;

    &::after {
      display: block;
      content: "";
      position: absolute;
      bottom: 0;
      margin-bottom: -1px;
      width: 100%;
      height: 20px;
      background: linear-gradient(to top, #252525, #25252500);
      pointer-events: none;
    }
  }

  &__scroll-container {
    height: 170px;
    margin-bottom: 10px;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;

    p {
      font-size: 10px;
      line-height: 12px;
      span {
        font-weight: 800;
      }
    }

    &::-webkit-scrollbar {
      display: none;
    }

    p {
      margin-bottom: 10px;
      text-align: left;
      text-align: justify;
      text-justify: inter-word;
    }
  }
  &__footer-full-mode {
    display: flex;
    justify-content: left;
    align-items: center;
    margin-top: 20px;

    button {
      margin-right: 10px;
    }
  }
  &__footer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;

    button {
      margin: 0 10px;
    }
  }
}
