$headerHeight: 86px;
$footerHeight: 95px;
$green: #55ffc5;

.staking-leaderboard {
  $root: &;

  &__title {
    margin-bottom: 10px;
  }

  &__copy {
    margin-bottom: 25px;
  }

  &__cup {
    position: absolute;
    width: 31px;
    fill: #66cc9a;
    opacity: 0.3;
    right: 5px;

    @media (min-width: 767px) {
      right: auto;
      left: 5px;
    }
  }

  &__arrows {
    width: 23px;
    fill: #66cc9a;
    position: relative;
    top: 10px;
    left: 10px;
  }

  &__header {
    color: #fff;
  }

  &__table {
    td:first-child {
      font-family: Trivial, sans-serif;
      color: #66cc9a;
    }

    tbody tr {
      &[data-index="0"],
      &[data-index="1"],
      &[data-index="2"] {
        background: linear-gradient(
          90deg,
          #66cc9a00 0%,
          #66cc9a25 30%,
          #66cc9a00 100%
        );
      }

      &#{$root}__row {
        &--yours {
          background: linear-gradient(
            90deg,
            #ffffff00 0%,
            #ffffff25 30%,
            #ffffff00 100%
          );

          td {
            &:first-child {
              border-top: 1px solid #66cc9a;
            }

            &:last-child {
              border-bottom: 1px solid #66cc9a;
            }

            @media (min-width: 767px) {
              border-top: 1px solid #66cc9a;
              border-bottom: 1px solid #66cc9a;
            }
          }
        }
      }
    }
  }
}
