.price {
  background: #1d1d1d;
  border: 1px solid #5b5b5b;
  border-radius: 14px;
  padding: 8px 25px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  color: #fff;
  font-family: Poppins, sans-serif;

  &__logo {
    display: flex;
    align-items: center;
    font-family: "Trivial", sans-serif;
  }

  svg {
    height: 26px;
    width: 26px;
    margin-left: 5px;
  }
}
