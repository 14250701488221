.tooltip {
  position: relative;
  display: flex;
  align-items: center;
  font-family: "Poppins", sans-serif;
  white-space: pre-line;
  font-size: 12px;
  font-weight: 500;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 280px;
  background-color: #626262;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 4px;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -140px;
  margin-bottom: 6px;
  opacity: 0;
  transition: all 0.5s ease-out;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #626262 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}