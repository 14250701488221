.footer {
  background-color: #252525;
  padding: 37px 0;

  &__icons {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 15px;
  }

  &__icon {
    margin-left: 36px;
    width: 17px;

    svg {
      width: 100%;
      height: 100%;
      fill: #fff;
    }
  }

  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    &__divider {
      margin-left: 16px;
      width: 1px;
      height: 21px;
      background-color: rgba(255, 255, 255, 0.5);
    }
    &__links {
      margin-left: 16px;
      a {
        color: #fff;
        transition: text-shadow 0.2s linear;
        white-space: nowrap;
        text-decoration: none;
        padding-right: 8px;
        &:hover {
          text-shadow: 0px 0px 3px rgba(255, 255, 255, 0.712);
        }
        &:last-child {
          padding-right: 0px;
        }
      }
    }
    &__copy {
      text-align: right;
      white-space: nowrap;
    }
  }

  @media (min-width: 767px) {
    &__container {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__icons {
      margin: 0;
    }

    &__icon {
      margin: 0 36px 0 0;
    }
  }
}
