.progress-bar {
  $root: &;
  text-align: center;

  &__title {
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  &__desc {
    font-size: 11px;
  }

  &__items {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  &__icon {
    width: 100%;
    height: 100%;
    fill: #40444a;
    transition: fill 500ms 500ms ease;

    .progress-bar__particle-1,
    .progress-bar__particle-2,
    .progress-bar__particle-3,
    .progress-bar__particle-4 {
      transition: transform 500ms 500ms ease;
    }

    .progress-bar__particle-1 {
      transform: translate(10%, 8%);
    }

    .progress-bar__particle-2 {
      transform: translate(-5%, 10%);
    }

    .progress-bar__particle-3 {
      transform: translate(10%, 20%);
    }

    .progress-bar__particle-4 {
      transform: translate(-20%, 10%);
    }

    &--active {
      .progress-bar__particle-1,
      .progress-bar__particle-2,
      .progress-bar__particle-3,
      .progress-bar__particle-4 {
        transform: translate(0, 0);
      }
    }
  }

  &__item {
    box-sizing: border-box;
    height: 66px;
    width: 66px;
    border: 2px solid #40444a;
    border-radius: 100%;
    transition: border-color 500ms 500ms ease;
    flex-shrink: 0;
    padding: 15px;

    &--active {
      border-color: #66cc9a;

      #{$root}__icon {
        fill: #66cc9a;
      }

      + #{$root}__line::after {
        animation: slowLineAnimation 60s linear forwards;
      }
    }
  }

  &__line {
    background: #40444a;
    height: 2px;
    overflow: hidden;
    position: relative;
    width: 100%;

    &::after,
    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #66cc9a;
      transform: translateX(-100%);
      transition: transform 500ms ease;
    }

    &--active::before {
      transform: translateX(0%);
    }
  }
}

@keyframes slowLineAnimation {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(-20%);
  }
}
