.space-cartels-amulets {
  &__name {
    font-size: 21px;
  }

  &__info {
    color: #66cc9a;
    font-size: 14px;
    font-family: Trivial, sans-serif;
    margin: 10px 0;

    span {
      color: #fff;
    }
  }
}
