.modal {
  $root: &;
  box-sizing: border-box;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 9999;
  top: 0;
  left: 0;
  backdrop-filter: blur(6px);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;

  &__body {
    position: relative;
    background: #252525;
    border-radius: 10px;
    padding: 40px 20px;
    width: 300px;

    @media (min-width: 1200px) {
      padding: 40px 50px;
      width: 480px;
    }
  }

  &__close {
    position: absolute;
    top: 8px;
    right: 8px;
    fill: #fff;
    height: 8px;
    width: 10px;
    cursor: pointer;
    padding: 15px;
  }

  &--type-success {
    #{$root}__body {
      box-shadow: 0 0 6px #66cc9a;
      border: 2px solid #66cc9a;
    }
  }

  &--type-error {
    #{$root}__body {
      box-shadow: 0 0 6px #f85252;
      border: 2px solid #f85252;
    }
  }

  &--type-warning {
    #{$root}__body {
      box-shadow: 0 0 6px #ffbb00;
      border: 2px solid #ffbb00;
    }
  }
}
