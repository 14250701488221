.chance {
  @media (min-width: 767px) {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  &__item {
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    @media (min-width: 767px) {
      margin-bottom: 20px;
      margin-right: 8px;
    }

    &:nth-child(1) {
      .chance__percent {
        border-color: #ffe9ad;
        box-shadow: 0 0 6px #ffe9ad, inset 0 0 6px #ffe9ad;
      }
    }

    &:nth-child(2) {
      .chance__percent {
        border-color: #9adefa;
        box-shadow: 0 0 6px #9adefa, inset 0 0 6px #9adefa;
      }
    }

    &:nth-child(3) {
      .chance__percent {
        border-color: #9ceac7;
        box-shadow: 0 0 6px #9ceac7, inset 0 0 6px #9ceac7;
      }
    }

    &:nth-child(4) {
      .chance__percent {
        border-color: #157eb0;
        box-shadow: 0 0 6px #157eb0, inset 0 0 6px #157eb0;
      }
    }

    &:nth-child(5) {
      .chance__percent {
        border-color: #259dd5;
        box-shadow: 0 0 6px #259dd5, inset 0 0 6px #259dd5;
      }
    }
  }

  &__percent {
    height: 48px;
    width: 48px;
    border-radius: 100%;
    border: 2px solid;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Poppins, sans-serif;
    color: #fff;
    font-size: 15px;
    font-weight: bold;
    margin-right: 8px;
  }

  &__title {
    margin-right: 12px;
    color: #fff;
  }
}
