.title {
  $root: &;
  font-size: 32px;
  font-family: "Trivial", sans-serif;
  color: #66cc9a;
  line-height: 40px;

  &--animate {
    transform: translateX(-20px);
    opacity: 0;
    transition: opacity 1s ease-in-out, transform 1s ease-in-out;
  }

  &--visible {
    opacity: 1;
    transform: translateX(0px);
  }

  &__suffix {
    color: #fff;
  }

  &--inverted {
    color: #fff;

    #{$root}__suffix {
      color: #66cc9a;
    }
  }

  @media (min-width: 767px) {
    font-size: 36px;
  }
}
