.mini-button {
  border: 1px solid #66cc9a;
  border-radius: 3px;
  padding: 3px 10px;
  margin: 0;
  appearance: none;
  background: transparent;
  color: #fff;
  font-size: 12px;
  cursor: pointer;

  transition: all 0.3s ease-out;

  &:hover,
  &--active {
    background: #66cc9a;
    color: #000;
  }
}
