.instant-withdraw-confirmation-popup {
  display: block;
  text-align: center;

  &__title-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
  }

  &__container-confirmation-item {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }

  &__confirmation-text {
    font-size: 13px;
    line-height: 1.5;
    text-align: left;
    font-family: "Poppins", sans-serif;
    color: #fff;
    cursor: pointer;

    a {
      color: white;
    }

    span {
      color: #66cc9a;
    }
  }

  &__title.text {
    font-size: 18px;
    font-weight: bold;
  }

  &__icon {
    fill: #66cc9a;
    width: 26px;
    height: 23px;
    min-width: 26px;
    min-height: 23px;
    margin-left: 10px;
  }

  &__gradient-container {
    position: relative;

    &::after {
      display: block;
      content: "";
      position: absolute;
      bottom: 0;
      margin-bottom: -1px;
      width: 100%;
      height: 20px;
      background: linear-gradient(to top, #252525, #25252500);
      pointer-events: none;
    }
  }

  &__scroll-container {
    height: 170px;
    margin-bottom: 10px;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;

    p {
      font-size: 10px;
      line-height: 12px;
      span {
        font-weight: 800;
      }
    }

    &::-webkit-scrollbar {
      display: none;
    }

    p {
      margin-bottom: 10px;
      text-align: justify;
      text-justify: inter-word;
    }
  }

  &__footer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;

    button {
      margin: 0 10px;
    }
  }
}
