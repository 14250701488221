.hamburger {
  $root: &;
  height: 22px;
  width: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  appearance: none;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;

  &__line {
    height: 2px;
    background: #fff;
    width: 100%;
    transition: transform 250ms ease, width 250ms ease, opacity 250ms ease;

    &:first-child {
      width: 13px;
    }

    &:last-child {
      width: 27px;
    }
  }

  &--is-open {
    #{$root}__line {
      transform-origin: center center;

      &:first-child {
        opacity: 0;
      }

      &:nth-child(2) {
        transform: rotate(45deg);
        width: 27px;
      }

      &:last-child {
        transform: translateY(-10px) rotate(-45deg);
      }
    }
  }
}
