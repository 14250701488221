.claim-mint {
  &__title-container {
    margin-bottom: 30px;
  }

  &__title.title {
    margin-bottom: 10px;
  }

  &__inputs {
    & > div {
      margin-bottom: 30px;
    }

    @media (min-width: 1200px) {
      display: flex;
      justify-content: space-between;

      & > div:first-of-type {
        margin-right: 40px;
        width: 60%;
      }

      & > div:last-of-type {
        width: 40%;
      }
    }
  }

  &__balance {
    text-align: right;
    margin-top: 10px;
    padding-right: 20px;
  }

  &__details {
    border-top: 1px solid #40444a;
    margin-top: 20px;
    padding-top: 20px;

    & > div {
      display: block;
      margin: 0 auto;
    }

    @media (min-width: 1200px) {
      padding-top: 40px;
    }
  }

  &__button {
    display: block;

    @media (min-width: 1200px) {
      display: none;
    }
  }

  &__rules {
    padding: 6px 0;

    span {
      color: #66cc9a;
    }
  }

  &__text-line {
    padding: 4px 0;
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }

  &__timer {
    text-align: center;
  }
}

// FYI: these styles are also used on treasury page & airdrop page
.claim-label {
  background: #252525;
  border: 2px solid #66cc9a;
  box-shadow: 0 0 6px #66cc9a;
  border-radius: 16px;
  padding: 20px 25px;
  display: flex;
  align-items: center;

  &__title {
    font-size: 18px;
    color: #66cc9a;
    font-family: Poppins, sans-serif;

    @media (min-width: 1200px) {
      span {
        display: none;
      }
    }
  }

  &__currency {
    margin-left: auto;
    font-size: 0;
    color: #878787;
    font-family: Trivial, sans-serif;
    font-weight: bold;
    display: flex;
    align-items: center;

    @media (min-width: 1200px) {
      font-size: 14px;
    }

    img,
    svg {
      height: 40px;
      width: 40px;
      margin-left: 10px;
    }
  }

  &__input {
    display: block;
    background: transparent;
    border: none;
    font-size: 18px;
    color: #fff;
    margin-bottom: 10px;
    font-family: Poppins, sans-serif;
    font-weight: bold;
    width: 100%;

    &:active,
    &:focus {
      outline: none;
    }
  }

  &__button {
    display: none;

    @media (min-width: 1200px) {
      display: block;
      margin-left: 20px;
    }

    &--mobile {
      display: block;

      @media (min-width: 1200px) {
        display: none;
      }
    }
  }
}
