.navigation {
  position: relative;
  z-index: 10;
}

.navbar-wrapper {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 3;
  backdrop-filter: blur(3px);
  background-color: rgba(0, 0, 0, 0.4);
}

.navbar {
  border-bottom: #5b5b5b 0.5px solid;

  &__container {
    display: flex;
    position: relative;
    color: white;
    justify-content: space-between;
    align-items: center;
    height: 85px;
  }

  &__nav-to-buy-chest,
  &__nav-to-leaderboard,
  &__nav-to-dashboard,
  &__nav-item {
    display: none;
  }

  @media (min-width: 1200px) {
    &__nav-to-buy-chest,
    &__nav-to-leaderboard,
    &__nav-to-dashboard,
    &__nav-item {
      display: block;
      text-decoration: none;
      margin: 0 12px 0 0;

      button {
        color: #fff;
      }
    }

    .navbar__nav-item:nth-child(2) {
      margin-left: auto;
    }

    &__nav-to-buy-chest {
      margin-left: 30px;
    }

    &__nav-to-dashboard {
      margin-left: -20px;
    }
  }

  .logo {
    display: flex;
  }

  .contact-us {
    z-index: 1;
    border-style: solid;
    border-width: 2px;
    transition: border 0.5s linear;
    border-image: linear-gradient(
        50deg,
        rgba(0, 0, 0, 0) 40%,
        rgba(102, 204, 154, 1)
      )
      1;

    &:hover {
      cursor: pointer;
      border-image: linear-gradient(
          50deg,
          rgba(0, 0, 0, 0) 15%,
          rgba(102, 204, 154, 1)
        )
        1;
    }

    p {
      font-family: "Poppins", sans-serif;
      font-size: 14px;
      font-weight: 600;
      margin: 12px 16px;
    }
  }

  &__wallet {
    position: relative;
    font-family: Poppins, sans-serif;
    color: black;
    margin-left: auto;

    @media (min-width: 850px) {
      margin-right: 30px;
    }

    @media (min-width: 1200px) {
      margin-left: 0;
      margin-right: 0;
    }
    // end fix
  }

  &__menu.hamburger {
    @media (min-width: 1200px) {
      display: none;
    }
  }

  &__testnet-info {
    width: 100%;
    height: 28px;
    background-color: #ffbb00;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      font-family: Trivial, sans-serif;
      font-size: 13px;
      font-weight: bold;
    }
  }
}

// WALLET
.WithStyles\(ForwardRef\(Button\)\)-root-1 {
  background-color: #252525 !important;

  &:hover {
    color: #fff !important;
    background-color: #252525 !important;
  }
}

.WithStyles\(ForwardRef\(Button\)\)-root-5 {
  background-color: #252525 !important;

  :hover {
    background-color: #252525 !important;
  }
}

@media screen and (min-width: 640px) {
  .wallet-content {
    position: absolute;
    display: block;
    top: 110%;
    right: 0;
    border-radius: 15px;
    border: 1px solid rgba(102, 204, 154, 0.5);
  }
}

.wallet-section div:nth-child(3) {
  display: none;
}

@media screen and (max-width: 850px) {
  .wallet-content {
    margin-top: 45px;
    margin-right: -10px;
    padding-top: 22px;
  }

  .wallet-section-close {
    margin-top: -22px;
    margin-right: -5px;
    display: flex;
  }
  .wallet-section div:nth-child(3) {
    display: block;
  }
  .wallet-section div:nth-child(4) {
    display: none;
  }
}

.wallet-connect-button {
  font-weight: bold;
}
.wallet-connect-addess {
  color: #fff;
}

.wallet-balance-button {
  font-weight: 700;
  position: relative;
  display: inline-block;
  margin-left: 1em;
  padding-left: 1em;
}
@media screen and (min-width: 640px) {
  .wallet-balance-button::before {
    content: "|";
    position: absolute;
    left: 0;
  }
}

.wallet-section {
  color: #fff;
  padding: 5px 15px;
}
.wallet-section-close {
  float: right;
}
.wallet-disconnect:hover {
  border: none;
}
.wallet-header {
  margin: 30px 0 10px 0;
  display: flex;
  font-size: 10px;
}
@media screen and (min-width: 640px) {
  .wallet-header {
    margin: 10px 0;
    font-size: 11px;
  }
}
.wallet-balance-section {
  padding: 0 0 15px 0;
  border-top: 1px solid #40444a;
}
.wallet-balance-item {
  border-bottom: 1px solid #40444a;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 35px;
  font-size: 14px;
}

.wallet-button {
  width: 100%;
  text-transform: initial !important;
  font-size: 11px !important;
}

.wallet-contect-options-title {
  color: #66cc9a;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  font-family: Trivial, sans-serif;
}

.wallet-contect-options-title-second {
  color: #fff;
}

.wallet-contect-options-title-close {
  position: absolute !important;
  top: 5px;
  right: 15px;
}
.wallet-contect-options-content {
  display: flex;
  flex-direction: column;
  margin: 20px;

  & button {
    margin-bottom: 10px;
  }
}

.wallet-content-animated {
  position: absolute;
  display: block;
  opacity: 0;
  top: 5px;
  right: -200px;
}

.wallet-content-animated-move {
  transition: all 0.5s;
  opacity: 1;
  position: absolute;
  display: block;
  top: 5px;
  right: 10px;
  border-radius: 15px;
}

.wallet-empty {
  max-width: 150px;
}
