$footerHeight: 95px;

.staking {
  $root: &;

  .virtual-list {
    padding-bottom: #{$footerHeight};
  }

  .unbonding_table_timer {
    font-size: 18px;
    justify-content: flex-start;
  }

  &__columns {
    @media (min-width: 1200px) {
      display: flex;
    }
  }

  &__tabs {
    @media (min-width: 1200px) {
      min-width: 300px;
      width: 40%;
      padding-right: 95px;
    }
  }

  &__tabs-item {
    margin-bottom: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid rgba(135, 135, 135, 0.5);

    &:last-child {
      border: 0;
    }
  }

  &__chart {
    display: none;

    @media (min-width: 1200px) {
      display: block;
    }
  }

  &__content {
    width: 100%;
  }

  &__header {
    text-align: center;
    margin-bottom: 25px;

    @media (min-width: 1200px) {
      border-bottom: 1px solid rgba(135, 135, 135, 0.5);
      display: flex;
      justify-content: space-around;
    }
  }

  &__add-sign {
    margin-left: 10px;
    margin-right: 10px;
  }
  
  &__token-logo{
    margin-left: 4px;
    width: 40px;
  }
  
  &__apr-container{
    display: flex;
    align-items: center;
  }

  &__header-value {
    font-size: 21px;
    line-height: 21px;
  }

  &__arrow {
    display: block;
    height: 19px;
    width: 20px;
    margin: 10px auto 40px;
  }

  &__period {
    margin-bottom: 25px;
  }

  &__details {
    border-top: 1px solid #40444a;
    border-bottom: 1px solid #40444a;
    margin-top: 20px;
    padding: 20px;

    &--highlighted {
      padding: 20px 0;

      .text {
        font-family: Trivial, sans-serif;
      }

      #{$root}__detail {
        color: #878787;
        font-size: 14px;
      }

      #{$root}__value {
        color: #66cc9a;
        font-size: 21px;
      }
    }
  }

  &__details-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;

    &:last-child {
      margin: 0;
    }
  }

  &__button {
    margin-top: 25px;
  }

  &__claim-rewards-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 40px;
    @media (min-width: 1200px) {
      flex-direction: row;
    }

    a {
      display: contents;
    }
  }

  &__claim_rewards_button {
    margin: 25px 10px 0 10px;
  }

  &__detail {
    display: flex;
    color: #fff;
  }

  &__tables {
    padding-top: 50px;

    tfoot {
      display: none;

      @media (min-width: 766px) {
        display: table-footer-group;
      }
    }
  }

  &__table-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  &__condensed_input_container {
    margin-top: 20px;
  }


}
