table {
  width: 100%;
  font-size: 14px;
  margin-bottom: 50px;
}

tr {
  border-bottom: 1px solid #40444a;
}

th {
  font-family: Trivial, sans-serif;
  color: #66cc9a;
  text-align: left;
  padding: 0 20px 20px 0;

  &:last-child {
    padding-right: 0;
  }
}

td {
  position: relative;
  font-family: Poppins, sans-serif;
  color: #fff;
  padding: 20px 20px 20px 0;

  &:last-child {
    padding-right: 0;
  }
}

// RESPONSIVE TABLE
// https://css-tricks.com/responsive-data-tables/
@media (max-width: 766px) {
  /* Force table to not be like tables anymore */
  table, thead, tbody, th, td, tr {
    display: block;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  tbody tr:first-child {
    border-top: 1px solid #40444a;
  }

  td {
    /* Behave  like a "row" */
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border: 0;
    position: relative;
    text-align: right;
    padding: 5px 0;

    &:first-child {
      padding-top: 20px;
    }

    &:last-child {
      padding-bottom: 20px;
    }
  }

  td:before {
    content: var(--title);
    width: 50%;
    padding-right: 10px;
    text-align: left;
    font-family: Trivial, sans-serif;
    color: #66CC9A;
  }
}
