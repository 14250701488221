.period {
  text-align: center;

  &__title {
    display: flex;
    justify-content: center;
  }

  &__days {
    color: #55ffc5;
  }

  &__description-button .title {
    cursor: pointer;
    color: #fff;

    &:hover {
      color: #55ffc5;
    }
  }

  &__description {
    max-height: 0;
    margin-bottom: 15px;
    overflow: hidden;
    visibility: hidden;
    transition: visibility 500ms ease, max-height 500ms ease;

    &--visible {
      max-height: 100px;
      visibility: visible;
    }
  }

  &__slider {
    height: 34px;
  }

  &__thumb {
    top: -7px;
    width: 25px;
    cursor: pointer;

    &:focus-visible {
      outline: none;
    }

    .title {
      color: #66cc9a;
    }
  }

  &__indicator {
    transform: rotate(90deg);
    width: 25px;
    height: 17px;
  }

  &__track {
    background: #878787;
    height: 2px;

    &-0 {
      background: linear-gradient(90deg, #66cc9a 0%, #339a99 100%);
    }
  }

  &__min-max {
    display: flex;
    justify-content: space-between;
    margin-top: -21px;
  }

  &__min {
    margin-right: auto;
    width: 25px;
  }

  &__max {
    margin-left: auto;
    width: 25px;
  }
}
