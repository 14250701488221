.playnity-the-genesis-nft {
  &__name {
    font-size: 21px;
  }

  &__before-title {
    span {
      color: #66cc9a;
      font-weight: 700;
    }
  }

  &__description {
    line-height: 125%;
    margin: 10px 0 10px 0;
  }

  &__title-section {
    display: flex;
    align-items: center;
    margin: 20px 0;

    &-name {
      min-width: fit-content;
      padding-right: 60px;
      line-height: 20px;
    }
  }

  &__separator {
    width: 100%;
    height: 1px;
    background-color: #878787;
    margin: 20px 0;
  }

  &__level {
    display: flex;

    &-description {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
      &-line {
        padding: 2px 0;
        line-height: 20px;
        font-weight: 500;
        color: #fff;

        span {
          color: #66cc9a;
        }
      }
    }
  }

  &__total-burn {
    margin: 16px 0;
  }
  &__score-points {
    margin-bottom: 16px;
  }

  &__total-burn,
  &__score-points {
    &-title,
    &-line {
      padding: 2px 0;
      line-height: 20px;
      font-weight: 500;

      span {
        color: #66cc9a;
      }
    }
    &-line {
      color: #fff;
    }

    &-info {
      padding: 16px 0;
    }
  }

  &__burn-table {
    width: 100%;
    margin: 25px 0;
  }

  &__eth-address {
    margin-top: 25px;
    width: 100%;
    &-text {
      font-size: 12px;
      padding: 0 0 10px 0;
    }
    &-error {
      color: red;
      font-size: 12px;
      padding: 10px 0 0 0;
    }
  }

  &__button {
    margin-top: 25px;
    background-color: red;
  }

  &__fee-info {
    margin-top: 10px;
    text-align: right;
    font-size: 12px;
  }

  &__info {
    color: #66cc9a;
    font-size: 14px;
    font-family: Trivial, sans-serif;
    margin: 10px 0;

    span {
      color: #fff;
    }
  }

  &__none-message {
    width: 100%;
    text-align: center;
  }

  &__timer {
    justify-content: left;

    .timer__digit,
    .timer__colon {
      color: #fff;
      font-size: 14px;
      line-height: 18px;
      font-family: "Poppins", sans-serif;
    }
    .timer__description {
      display: none;
    }
  }

  &__info .text {
    font-family: Trivial, sans-serif;
    color: #66cc9a;

    span {
      float: right;
      color: #fff;
      text-transform: capitalize;
    }
  }

  @media screen and (min-width: 767px) {
    &__table-row {
      td,
      th {
        padding: 12px 12px 12px 0;
      }
    }
  }
}
