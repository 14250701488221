.box-images-carousel {
  display: block;
  width: 100%;
  position: relative;

  @media (max-width: 1200px) {
    width: 100%;
    max-width: 600px;
    margin: 0 auto 20px auto;
  }

  &__item {
    box-sizing: border-box;
    width: 100%;
    aspect-ratio: 3 / 3.2;
    transition: opacity 0.3s ease-out;

    &--hidden {
      top: 0;
      opacity: 0;
      position: absolute;
    }

    &--active {
      opacity: 1;
      position: relative;
    }
  }

  &__box {
    height: 95%;

    img {
      width: 100%;
    }
  }
}
