.homepage {
  &__timer {
    background: #1d1d1d;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .title {
      padding-top: 80px;
      margin-bottom: 50px;
    }

    .timer {
      transform: scale(1.5);

      @media (min-width: 1200px) {
        transform: scale(2);
      }
    }
  }

  &__buy-mint {
    background-color: #1d1d1d;
    padding-top: 0;
    min-height: 0;
  }

  &__treasury {
    background-color: #252525;
    padding-top: 87px;
    min-height: 0;
  }

  &__additional-info {
    border: 2px solid #66cc9a;
    padding: 0 14px 14px 14px;
    margin-bottom: 15px;
    border-radius: 14px;
    box-shadow: 0 0 6px #66cc9a;
    background: rgba(0, 0, 0, .7);

    .title {
      padding-top: 0;
    }

    a {
      color: #66cc9a;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
