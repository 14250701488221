.counter {
  $root: &;
  position: relative;

  overflow: hidden;
  padding-top: 10px;

  &::before {
    content: "";
    position: absolute;
    top: 14px;
    display: block;
    height: 2px;
    width: 100%;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 25%,
      rgba(255, 255, 255, 1) 75%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  &__items {
    position: relative;
    display: flex;
    justify-content: flex-start;
    transition: transform 250ms ease;

    &--animate {
      animation: move 500ms ease forwards;
    }
  }

  &__number {
    font-size: 18px;
    line-height: 24px;
  }

  &__indicator {
    height: 10px;
    width: 2px;
    background: #fff;
    margin: 0 auto 7px;
  }

  &__title {
    font-size: 14px;
    color: #878787;
    line-height: 21px;
    opacity: 0;
    transform: translateY(-5px);
  }

  &__leaf {
    height: 16px;
    transform: rotate(90deg) translateX(-2px);
    opacity: 0;
  }

  &__item {
    text-align: center;
    min-width: 33.3%;

    &--active {
      #{$root}__number {
        transform: scale(1.2);
        color: #fff;
        transition: transform 500ms ease, color 500ms ease;
      }

      #{$root}__indicator {
        background: none;
        height: auto;
        width: auto;
        margin: 0;
      }

      #{$root}__title {
        transition: transform 500ms 250ms ease, opacity 500ms 250ms ease;
        opacity: 1;
        transform: translateY(0px);
      }

      #{$root}__leaf {
        transition: opacity 500ms ease;
        opacity: 1;
      }
    }
  }
}

@keyframes move {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-33.333%);
  }
}
