$headerHeight: 86px;
$footerHeight: 95px;
$green: #66cc9a;

.leaderboard {
  min-height: calc(100vh - #{$footerHeight});
  background: #252525;
  display: flex;

  &__virtual-list {
    padding-bottom: #{$footerHeight};

    th {
      cursor: pointer;

      &:first-child {
        cursor: text;
      }
    }

    td {
      &:first-child {
        color: $green;
      }
    }
  }

  &__separator {
    width: 100%;
    height: 1px;
    background-color: #40444a;
    margin: 6px 0;
  }

  &__logo {
    display: none;
    pointer-events: none;

    @media (min-width: 767px) {
      display: block;
      position: fixed;
      height: calc(100% - #{$headerHeight});
      top: $headerHeight;
      transition: opacity 1s ease-in-out, transform 1s ease-in-out;
      opacity: 0;

      &--visible {
        opacity: 0.1;
        transform: translateX(-50%);
      }
    }
  }

  &__page-title {
    display: flex;
    font-weight: 700;
  }

  &__selected-collection {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 10px 0;
  }

  &__my-nfts {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    padding-top: 20px;
    width: 100%;

    @media (min-width: 767px) {
      justify-content: center;
      flex-direction: row;
      flex-wrap: wrap;
      margin: 0 -30px;
      width: calc(100% + 60px);
    }

    &-filter {
      @media (min-width: 767px) {
        width: 70%;
      }

      width: 100%;
      margin: 0 0 20px 0;
      display: flex;
      justify-content: space-around;
      z-index: 1;
      div {
        width: 70%;
      }
    }
  }

  &__container {
    width: 100%;
    padding-bottom: 93px;
    padding-top: 144px;
    display: flex;
    flex-direction: column;
  }

  &__table-row-link {
    transition: all 0.3s ease-out;
    &:hover {
      cursor: pointer;
      background-color: rgba(102, 204, 154, 0.04);
    }
  }

  &__title {
    span {
      color: $green;
      font-weight: bold;
    }
  }

  & + .footer {
    background: #1d1d1d;
  }
}
