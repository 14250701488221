.container {
  padding: 0 20px;

  @media (min-width: 767px) {
    padding: 0 60px;
  }

  @media (min-width: 1200px) {
    padding: 0 125px;
    max-width: 1116px;
    margin: 0 auto;

    &--narrow {
      padding: 0;
      max-width: 926px;
    }
  }
}
