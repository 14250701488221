$headerHeight: 86px;
$footerHeight: 95px;

.dashboard {
  min-height: calc(100vh - #{$footerHeight});
  background: #252525;
  display: flex;

  &__circular-progress {
    color: #55ffc5 !important;
  }

  &__timer-wrapper {
    display: flex;
  }
  &__timer {
    margin: 0 0 0 10px;
    .timer__digit,
    .timer__colon {
      font-size: 12px;
      line-height: 18px;
    }
  }

  &__separator {
    width: 100%;
    height: 1px;
    background-color: #40444a;
    margin: 6px 0;
  }

  &__logo {
    display: none;
    pointer-events: none;

    @media (min-width: 767px) {
      display: block;
      position: fixed;
      height: calc(100% - #{$headerHeight});
      top: $headerHeight;
      transition: opacity 1s ease-in-out, transform 1s ease-in-out;
      opacity: 0;

      &--visible {
        opacity: 0.1;
        transform: translateX(-50%);
      }
    }
  }

  &__message {
    width: 100%;
    height: 100%;
    margin: 100px 0;
    display: flex;
    justify-content: space-around;
    align-items: center;

    p {
      font-size: 18px;
    }
  }

  &__mint-token-icon {
    width: 30px;
    height: 30px;
    padding: 0 6px;
  }

  &__page-title {
    display: flex;
    font-weight: 700;
  }

  &__nav {
    display: flex;
    flex-direction: row;
    margin-bottom: 34px;

    @media (min-width: 767px) {
      display: flex;
      flex-direction: row-reverse;
      margin-bottom: 34px;
    }

    &-item {
      padding-top: 10px;
      font-weight: 700;

      p {
        font-size: 18px;
        color: #66cc9a;
        &::before {
          content: "";
          position: absolute;
          margin-top: 26px;
          height: 1px;
          width: 47px;
          background-color: #66cc9a;
        }
        &--active {
          color: #66cc9a;
        }
      }
    }
  }

  &__my-nfts {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    padding-top: 20px;
    width: 100%;

    @media (min-width: 767px) {
      align-items: stretch;
      justify-content: flex-start;
      flex-direction: row;
      flex-wrap: wrap;
      margin: 0 -30px;
      width: calc(100% + 60px);
    }
  }

  &__item {
    box-sizing: border-box;
    width: 100%;
    margin-bottom: 20px;
    font-weight: 700;
    max-width: 260px;

    @media (min-width: 767px) {
      display: flex;
      flex-direction: column;
      max-width: none;
      width: calc(50% - 60px);
      margin: 0 30px 20px;
    }

    @media (min-width: 1200px) {
      width: calc(33.333% - 60px);
    }

    &-box {
      box-sizing: border-box;
      aspect-ratio: 3 / 3.2;
      margin: 0 auto;
      width: 100%;
    }

    @media (min-width: 767px) {
      &-info {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
      }
    }

    &-image {
      width: 100%;
      cursor: pointer;
      transition: all 0.3s ease-out;

      &:hover {
        filter: saturate(2);
      }
    }

    &-title {
      font-size: 14px;
      white-space: nowrap;

      @media (min-width: 767px) and (max-width: 1200px) {
        font-size: 16px;
      }
    }
    &-attribute {
      font-size: 14px;

      span {
        color: #878787;
        text-transform: uppercase;
      }
    }
    &-reward {
      margin-top: auto;

      &-info {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .btn {
        width: 100%;
        margin: 6px 0;
      }
    }

    &-mint {
      display: flex;
      align-items: center;
    }
  }

  &__container {
    width: 100%;
    padding-bottom: 93px;
    padding-top: 144px;
    display: flex;
    flex-direction: column;
  }

  &__boxes {
    margin-bottom: 36px;
  }

  &__photos {
    @media (min-width: 1200px) {
      min-width: 400px;
      margin-right: 72px;
    }
  }

  &__sub-title {
    &--small {
      font-size: 10px;
    }

    span {
      color: #55ffc5;
      font-weight: bold;
    }
  }

  &__starts {
    text-align: center;
    position: relative;

    > .title {
      display: inline-block;
      background: #252525;
      padding: 0 15px;
    }

    &::before {
      content: "";
      display: block;
      height: 1px;
      width: 100%;
      position: absolute;
      background: #40444a;
      top: 50%;
      left: 0;
    }
  }

  &__chance {
    margin-bottom: 20px;
  }

  &__footer {
    border-top: 1px solid #878787;
    padding-top: 20px;
  }

  & + .footer {
    background: #1d1d1d;
  }
}

.virtuoso-grid-item {
  justify-content: center;
}
