.circle {
  @media (min-width: 767px) {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  &__item {
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    @media (min-width: 767px) {
      margin-bottom: 20px;
      margin-right: 17px;
    }
  }

  &__percent {
    height: 48px;
    width: 48px;
    border-radius: 100%;
    border: 2px solid;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Poppins, sans-serif;
    color: #fff;
    font-size: 15px;
    font-weight: bold;
    margin-right: 8px;

    // default color
    border-color: #5cc39a;
    box-shadow: 0 0 6px #5cc39a, inset 0 0 6px #5cc39a;
  }

  &__title {
    color: #fff;
  }
}
