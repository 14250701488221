.robohero-avatar {
  &__name {
    font-size: 21px;
  }

  &__info {
    color: #66cc9a;
    font-size: 14px;
    font-family: Trivial, sans-serif;
    margin: 10px 0;

    span {
      color: #fff;
    }
  }

  $green: #66cc9a;

  &__description {
    p {
      margin: 0 0 12px 0;
    }

    &-benefits {
      margin-left: 20px;
      list-style: circle;
      color: white;
      p {
        line-height: 150%;
        margin: 0;
        font-size: 14px;

        span {
          color: #66cc9a;
        }
      }
    }
  }
}
