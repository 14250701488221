.text {
  font-size: 14px;
  line-height: 21px;
  font-family: "Poppins", sans-serif;
  color: #fff;

  strong {
    font-weight: bold;
    color: #66cc9a;
  }
}
