.static-slider {
  $root: &;
  position: relative;
  width: 4px;

  @media (min-width: 1200px) {
    width: 100%;
  }

  &__track {
    width: 2px;
    height: 100%;
    background: #4d4d4d;
    position: absolute;
    left: 4px;

    @media (min-width: 1200px) {
      width: 100%;
      height: 2px;
      left: 0;
      top: 4px;
    }
  }

  &__fill {
    position: absolute;
    background: #66cc9a;
    height: var(--percent);
    width: 100%;
    bottom: 0;

    @media (min-width: 1200px) {
      height: 100%;
      width: var(--percent);
    }
  }

  &__items {
    display: flex;
    flex-direction: column-reverse;

    @media (min-width: 1200px) {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &__item {
    position: relative;
    height: 2px;
    width: 10px;
    background: #4d4d4d;
    margin-bottom: 50px;

    &:first-child {
      margin-bottom: 0;
    }

    @media (min-width: 1200px) {
      height: 10px;
      width: 2px;
      margin: 0;
    }

    &--before {
      background: #66cc9a;

      #{$root}__step {
        color: #4d4d4d;
      }
    }

    &--current {
      #{$root}__step {
        color: #66cc9a;
      }
    }

    &--after {
      #{$root}__step {
        color: #fff;
      }
    }
  }

  &__current {
    position: absolute;
    bottom: var(--percent);

    @media (min-width: 1200px) {
      bottom: 9px;
      left: var(--percent);
    }
  }

  &__step {
    font-family: Trivial, sans-serif;
    font-size: 15px;
    position: absolute;
    left: 20px;
    top: -7px;

    @media (min-width: 1200px) {
      top: 15px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &__indicator {
    height: 25px;
    width: 17px;
    position: absolute;
    top: -10px;
    left: -4px;

    @media (min-width: 1200px) {
      transform: rotate(90deg);
      top: -8px;
      left: -8px;
    }
  }

  &__title {
    font-family: Trivial, sans-serif;
    font-size: 15px;
    position: absolute;
    right: 20px;
    top: -22px;
    color: #66cc9a;
    text-align: right;

    @media (min-width: 1200px) {
      right: auto;
      left: 50%;
      transform: translateX(-50%);
      top: -22px;
      width: 200px;
      text-align: center;
    }
  }
}
