.loading-popup {
  display: block;
  text-align: center;

  &__title-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
    font-weight: bold;
  }

  &__progress {
    display: flex;
    justify-content: center;

    &--loading {
      .circle .bar .progress {
        background: #ffbb00;
      }
    }

    &--error {
      .circle .bar .progress {
        background: #f85252;
      }

      .circle .left .progress,
      .circle .right .progress {
        animation-duration: 2s;
      }
      .circle .right .progress {
        animation-delay: 2s;
      }
    }

    &--success {
      .circle .bar .progress {
        background: #66cc9a;
      }

      .circle .left .progress,
      .circle .right .progress {
        animation-duration: 2s;
      }
      .circle .right .progress {
        animation-delay: 2s;
      }
    }
  }

  &__copy {
    font-size: 11px;
  }

  &__footer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
  }
}

.circular {
  height: 66px;
  width: 66px;
  position: relative;
}

.circular .inner {
  position: absolute;
  z-index: 6;
  top: 50%;
  left: 50%;
  height: 62px;
  width: 62px;
  margin: -31px 0 0 -31px;
  background: #252525;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.circular .bar {
  position: absolute;
  height: 100%;
  width: 100%;
  background: transparent;
  border-radius: 100%;
  clip: rect(0px, 66px, 66px, 33px);
}

.circle .bar .progress {
  position: absolute;
  height: 100%;
  width: 100%;
  border-radius: 100%;
  clip: rect(0px, 33px, 66px, 0px);
  transition: background-color 500ms ease;
}

.circle .left .progress {
  z-index: 1;
  animation: left 10s linear both;
}

@keyframes left {
  100% {
    transform: rotate(180deg);
  }
}

.circle .right {
  transform: rotate(180deg);
  z-index: 3;
}

.circle .right .progress {
  animation: right 10s linear both;
  animation-delay: 10s;
}

@keyframes right {
  100% {
    transform: rotate(180deg);
  }
}
