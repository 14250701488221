.tabs {
  $root: &;
  display: flex;

  &__item {
    font-size: 18px;
    color: #fff;
    font-family: Poppins, sans-serif;
    font-weight: bold;
    padding: 15px;
    border-radius: 11px;
    cursor: pointer;

    &::after {
      content: "";
      display: block;
      width: 0;
      height: 1px;
      background: #66cc9a;
      margin-top: 10px;
      transition: width 250ms ease;
    }

    &--active {
      color: #66cc9a;
      background: #1d1d1d;

      &::after {
        width: 20px;
      }
    }
  }

  &--primary {
    #{$root}__item--active {
      background: linear-gradient(
        180deg,
        rgba(102, 204, 154, 0.15) 0%,
        rgba(51, 154, 153, 0.15) 100%
      );
    }
  }
}
