$headerHeight: 86px;
$footerHeight: 95px;

.app-bg-layout {
  background: #252525;
  padding-top: $headerHeight;
  min-height: calc(100vh - #{$footerHeight});
  box-sizing: border-box;
  display: flex;
  // @TODO MAKE THIS CONDITIONAL OR EXTRACT!
  // align-items: center;

  &__logo {
    display: none;
    pointer-events: none;

    @media (min-width: 767px) {
      display: block;
      position: fixed;
      height: calc(100% - #{$headerHeight} - #{$footerHeight});
      top: $headerHeight;
      transition: opacity 1s ease-in-out, transform 1s ease-in-out;
      opacity: 0;

      &--visible {
        opacity: 0.1;
        transform: translateX(-50%);
      }
    }
  }

  &__container {
    width: 100%;
    padding-top: 60px;
    padding-bottom: 60px;
    position: relative;
  }

  & + .footer {
    background: #1d1d1d;
  }
}
