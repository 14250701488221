$gapWidth: 20px;
$lightDividerColor: #878787;
$dimmedDividerColor: #40444a;

.nft-container {
  min-height: calc(100vh - 95px);
  background: #252525;
  display: flex;
  align-items: center;
  width: 100%;

  &__container {
    width: calc(100% - 40px);
    padding: 0;
    margin: 0 auto;
    padding-top: 144px;
    padding-bottom: 62px;

    @media (min-width: 1200px) {
      display: flex;
      justify-content: center;
      padding-bottom: 93px;
    }
  }

  &__box {
    box-sizing: border-box;
    width: 100%;

    @media (max-width: 1200px) {
      width: 90%;
      max-width: 500px;
      margin: 0 auto $gapWidth auto;
    }

    aspect-ratio: 1 / 1;
    transition: all 0.3s ease-out;

    img {
      aspect-ratio: 1 / 1;
      display: block;
      width: 100%;
    }

    &:hover {
      cursor: zoom-in;
      filter: saturate(2);
    }
  }

  &__photos {
    @media (min-width: 1200px) {
      min-width: 400px;
      margin-right: 72px;
    }
  }

  &__title {
    font-size: 24px;
    span {
      color: #66cc9a;
      font-weight: bold;
    }

    @media (max-width: 1200px) {
      font-size: 28px;
    }
  }

  &__footer {
    border-top: 1px solid $lightDividerColor;
    padding-top: $gapWidth;
    margin-top: $gapWidth;
  }

  & + .footer {
    background: #1d1d1d;
  }

  &__go-back-button {
    margin-top: $gapWidth;
    padding-top: $gapWidth;
    border-top: 1px solid $lightDividerColor;
  }

  &__harvest {
    &-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &-info-section {
      flex: 1;
      display: flex;
      justify-content: space-between;

      @media (min-width: 600px) {
        justify-content: unset;
      }
    }

    &-title {
      font-size: 14px;
      white-space: nowrap;

      @media (min-width: 767px) and (max-width: 1200px) {
        font-size: 16px;
      }
    }

    &-button {
      display: none;

      @media (min-width: 600px) {
        display: block;
      }

      &--mobile {
        margin-top: $gapWidth;
        padding-top: $gapWidth;
        border-top: 1px solid $dimmedDividerColor;

        @media (min-width: 600px) {
          display: none;
        }
      }
    }

    &-mint {
      display: flex;
      align-items: center;

      @media (min-width: 600px) {
        margin-left: 20px;
      }
    }

    &-attribute {
      font-size: 14px;

      span {
        color: #878787;
        text-transform: uppercase;
        vertical-align: sub;
      }
    }
  }

  &__mint-token-icon {
    width: 30px;
    height: 30px;
    padding: 0 6px;
  }
}
