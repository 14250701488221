.treasury {
  &__title {
    text-align: center;
    margin-bottom: -50px;
  }

  &__bg {
    overflow: hidden;
    margin: 0 -20px;
    max-width: 100vw;
    position: relative;

    @media (min-width: 1200px) {
      margin: auto;
      max-width: none;
    }
  }

  &__mobile {
    width: 460px;
    margin-left: 50%;
    transform: translateX(-50%);
    display: block;

    @media (min-width: 1200px) {
      display: none;
    }
  }

  &__desktop {
    display: none;

    @media (min-width: 1200px) {
      display: block;
      width: 930px;
      margin: 0 auto;
    }
  }

  // TODO find why it does not override
  &__amount {
    font-size: 21px !important;
    line-height: 21px !important;
    color: #fff !important;
    text-align: center !important;
  }

  &__desc {
    text-align: center;
    line-height: 18px;
  }

  &__ust {
    position: absolute;
    top: 695px;
    left: 50%;
    width: 47px;
    margin-left: -127px;

    @media (min-width: 1200px) {
      top: 80px;
      margin-left: -430px;
    }
  }

  &__luna {
    position: absolute;
    top: 695px;
    left: 50%;
    width: 47px;
    margin-left: -23px;

    @media (min-width: 1200px) {
      top: 323px;
      margin-left: -432px;
    }
  }

  &__mint {
    position: absolute;
    top: 695px;
    left: 50%;
    width: 47px;
    margin-left: 85px;

    @media (min-width: 1200px) {
      top: 203px;
      margin-left: 386px;
    }
  }

  &__info1 {
    position: absolute;
    top: 327px;
    left: 50%;
    width: 300px;
    margin-left: -150px;
  }

  &__info2 {
    position: absolute;
    top: 424px;
    left: 50%;
    width: 300px;
    margin-left: -150px;

    @media (min-width: 1200px) {
      top: 402px;
      margin-left: -295px;
    }
  }

  &__info3 {
    position: absolute;
    top: 516px;
    left: 50%;
    width: 300px;
    margin-left: -150px;

    @media (min-width: 1200px) {
      top: 402px;
      margin-left: 0;
    }
  }

  &__reserve_box {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__help_icon {
    width: 22px;
    margin-left: 8px;
  }

  &__inputs {
    padding-top: 20px;

    & > div {
      margin-bottom: 30px;
    }

    @media (min-width: 1200px) {
      display: flex;
      justify-content: space-between;

      & > div:first-of-type {
        margin-right: 40px;
        width: 40%;
      }

      & > div:last-of-type {
        width: 60%;
      }
    }
  }

  &__button {
    display: block;

    @media (min-width: 1200px) {
      display: none;
    }
  }

  .claim-label__title {
    font-size: 15px;
    text-align: left;
  }

  &__claim-split-box {
    display: flex;

    div {
      display: flex;
      flex-direction: column;
      margin: 0 10px 0 0;
    }

    div:first-child {
      border-right: 1px solid #40444a;
    }
  }
}

.treasury__dual-rewards {
  width: 100%;
  text-align: center;
  margin-top: 15px;
  font-size: 18px;
  font-family: Trivial, sans-serif;
}
