.buy-mint {
  &__title-container {
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;

    @media (min-width: 1200px) {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &__title.title {
    margin-bottom: 10px;
  }

  &__tabs {
    margin-top: 20px;
    margin-left: auto;

    @media (min-width: 1200px) {
      margin: 0;
    }
  }

  &__inputs {
    display: flex;
    flex-direction: column;

    @media (min-width: 1200px) {
      flex-direction: row;

      & > div {
        width: 100%;
      }
    }

    &--reversed {
      flex-direction: column-reverse;

      @media (min-width: 1200px) {
        flex-direction: row-reverse;
      }
    }
  }

  &__arrows {
    display: block;
    height: 20px;
    width: 28px;
    margin: 10px auto 40px;
    cursor: pointer;

    @media (min-width: 1200px) {
      margin: auto 50px;
      min-width: 28px;
      transform: rotate(90deg) translateX(-10px);
    }
  }

  &__details {
    border-top: 1px solid #40444a;
    border-bottom: 1px solid #40444a;
    margin-top: 20px;
    padding: 20px;

    .powered-by {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;

      svg {
        margin-left: 8px;
      }
    }

    @media (min-width: 1200px) {
      display: flex;
      flex-wrap: wrap;
    }
  }

  &__details-col {
    @media (min-width: 1200px) {
      width: 50%;
      box-sizing: border-box;

      &:first-child {
        padding-right: 70px;
      }

      &:last-child {
        padding-left: 70px;
      }
    }
  }

  &__detail {
    display: flex;
    color: #fff;
    margin-bottom: 8px;

    span {
      margin-left: auto;

      button {
        margin-left: 8px;
      }
    }
  }

  &__button {
    margin-top: 20px;

    @media (min-width: 1200px) {
      display: block;
      max-width: 260px;
      margin: 20px auto 0;
    }
  }

  &__stake-mint-button {
    padding-top: 10px;
  }
}
