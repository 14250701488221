.box {
  position: relative;

  &__border {
    width: 100%;
    background: var(--sectionBgColor);
    border: 2px solid #66cc9a;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;

    &--outer {
      height: 95%;
      position: relative;
      background: var(--sectionBgColor);

      &::before {
        content: "";
        display: block;
        height: 50px;
        width: 87px;
        position: absolute;
        top: -2px;
        left: -2px;
        background: linear-gradient(
          90deg,
          var(--sectionBgColor) 0%,
          var(--sectionBgColor) 33%,
          rgba(0, 0, 0, 0) 100%
        );
      }
    }

    &--inner {
      overflow: hidden;
      top: 8px;
      right: 8px;
      position: absolute;
      box-shadow: 0 0 6px #66cc9a, inset 0 0 6px #000;
    }
  }
}
