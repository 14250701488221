$green: #66cc9a;
.collection-sale {
  min-height: calc(100vh - 95px);
  background: #252525;
  display: flex;
  align-items: center;

  &__disclaimer {
    font-size: 10px;
  }

  &__description {
    p {
      margin: 0 0 12px 0;
    }

    &-benefits {
      margin-left: 20px;
      list-style: circle;
      color: white;
      p {
        line-height: 150%;
        margin: 0;
        font-size: 14px;

        span {
          color: #66cc9a;
        }
      }
    }
  }

  &__container {
    width: 100%;
    padding-top: 144px;
    padding-bottom: 62px;

    @media (min-width: 1200px) {
      display: flex;
      padding-bottom: 93px;
    }
  }

  &__boxes {
    margin-bottom: 36px;
  }

  &__counter {
    margin-bottom: 30px;
  }

  &__photos {
    @media (min-width: 1200px) {
      min-width: 380px;
      margin-right: 60px;
    }
  }

  &__pre-title {
    span {
      color: #66cc9a;
      font-weight: bold;
    }
  }

  &__title {
    font-size: 32px;
  }

  &__connect-wallet-message {
    p {
      font-size: 18px;
    }
  }

  &__starts {
    text-align: center;
    position: relative;

    > .title {
      display: inline-block;
      background: #252525;
      padding: 0 15px;
    }

    &-whitelisted {
      color: $green;
    }

    &::before {
      content: "";
      display: block;
      height: 1px;
      width: 100%;
      position: absolute;
      background: #40444a;
      top: 50%;
      left: 0;
    }
  }

  &__footer {
    border-top: 1px solid #878787;
    padding-top: 20px;
  }

  & + .footer {
    background: #1d1d1d;
  }
}
