$headerHeight: 86px;
$footerHeight: 95px;
$sectionBgColor: #252525;

.collections {
  min-height: calc(100vh - #{$footerHeight});
  background: #252525;
  display: flex;

  &__collection {
    width: 100%;
    &-title {
      margin: 10px 0 20px 0;

      &--right {
        text-align: right;
      }
    }

    &-container {
      background: $sectionBgColor center right;
      position: relative;
      width: 100%;
      height: 300px;
      padding: 0;
      margin: 0;
      border-radius: 15px;
      overflow: hidden;
      transition: filter 0.3s ease-out;
      background-size: cover;
      filter: grayscale(25%);

      &:hover {
        filter: grayscale(0%);
      }

      &--playnity-the-genesis {
        background-image: url("./playnity-the-genesis.jpg");
      }

      &--thorstarter-shields {
        background-image: url("./thorstarter-shields.jpg");
      }

      &--space-cartels-amulets {
        background-image: url("./space-cartels-amulets.jpg");
      }

      &--robohero-avatars {
        background-image: url("./robohero.jpg");
      }

      &--mint-chests {
        background-image: url("./chest.jpg");
      }
    }
  }
  &__buttons-wrapper {
    display: flex;
    position: absolute;
    bottom: 0;
    width: 100%;
    background: #0005;

    a {
      display: block;
      width: 100%;
      height: 75px;
      line-height: 75px;
      color: #fff;
      font-size: 14px;
      font-family: Trivial, sans-serif;
      text-decoration: none;
      text-align: center;
      transition: background-color 250ms ease, color 250ms ease;

      &:hover {
        color: #66cc9a;
        background: #0005;
      }

      @media (min-width: 767px) {
        font-size: 20px;
      }
    }
  }

  &__separator {
    width: 100%;
    height: 1px;
    background-color: #40444a;
    margin: 16px 0 6px 0;
  }
}
