.provide-lp {
  width: 100%;

  &__title-container {
    margin-bottom: 30px;
  }

  &__title.title {
    margin-bottom: 10px;
  }

  &__inputs {
    display: flex;
    flex-direction: column;
  }

  &__plus,
  &__arrow {
    display: block;
    height: 13px;
    width: 13px;
    margin: 10px auto 40px;
  }

  &__details {
    border-top: 1px solid #40444a;
    border-bottom: 1px solid #40444a;
    margin-top: 20px;
    padding: 20px;
  }

  &__detail {
    display: flex;
    color: #fff;
    margin-bottom: 8px;

    span {
      margin-left: auto;

      button {
        margin-left: 8px;
      }
    }
  }

  &__button {
    margin-top: 20px;
  }
}

.double-label {
  background: #252525;
  border: 2px solid #66cc9a;
  box-shadow: 0 0 6px #66cc9a;
  border-radius: 16px;
  padding: 20px 25px;

  &__row {
    display: flex;
    align-items: center;
  }

  &__currency {
    margin-left: auto;
    font-size: 0;
    color: #878787;
    font-family: Trivial, sans-serif;
    font-weight: bold;
    display: flex;
    align-items: center;

    @media (min-width: 1200px) {
      font-size: 14px;
    }

    img,
    svg {
      height: 40px;
      width: 40px;
      margin-left: 10px;
    }
  }

  &__line {
    height: 1px;
    background: #878787;
    margin: 25px 0;
  }

  &__input {
    display: block;
    background: transparent;
    border: none;
    font-size: 21px;
    color: #fff;
    font-family: Poppins, sans-serif;
    font-weight: bold;
    width: 100%;
  }
}
