.btn {
  background: none;
  padding: 0;
  margin: 0;
  appearance: none;
  border: none;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 600;
  position: relative;
  z-index: 1;

  &:hover {
    cursor: pointer;
  }

  &.btn-contained {
    height: 43px;
    line-height: 43px;
    text-align: center;
    background: linear-gradient(to right, #66cc9a, #339a99);
    color: black;
    border-radius: 12px;
    padding: 0 32px;
    &:before {
      position: absolute;
      content: "";
      transition: all 0.3s ease-out;
      box-shadow: rgba(102, 204, 154, 0.7) 0 0 4px 0;
      border-radius: 12px;
      background: linear-gradient(to right, #66cc9a, #44aa99);
      width: calc(100% + 4px);
      height: calc(100% + 4px);
      top: -2px;
      left: -2px;
      display: block;
      z-index: -1;
      opacity: 0;
    }
    &:hover {
      &:before {
        opacity: 1;
      }
    }
  }
  &.btn-contained-disabled {
    height: 43px;
    line-height: 43px;
    text-align: center;
    opacity: 0.5;
    background: #626262;
    color: white;
    border-radius: 12px;
    padding: 0 32px;
  }
  &.btn-outlined {
    color: white;
    line-height: 43px;
    position: relative;
    text-align: right;
    padding: 6px 25px;
    svg {
      position: absolute;
      width: 90%;
      height: 100%;
      right: 0;
      top: 0;
      border-radius: 25px;
      z-index: 1;
      transition: all 0.3s ease-out;
      rect {
        opacity: 1;
        transition: all 0.3s ease-out;
      }
    }
    &:hover {
      svg {
        width: 110%;
        rect {
          opacity: 1;
        }
      }
    }
  }

  &--full-width {
    width: 100%;
  }
}
