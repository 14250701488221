.message-bar {
  $root: &;
  margin: 20px 0;
  background: rgb(37, 37, 37);
  border-radius: 14px;

  min-height: 43px;
  display: flex;
  align-items: center;

  font-family: "Poppins", sans-serif;
  font-size: 13px;
  font-weight: 500;
  color: white;

  p {
    padding: 15px 15px;
  }

  &-error {
    background: linear-gradient(
      90deg,
      rgba(37, 37, 37, 1) 0%,
      rgba(248, 82, 82, 0.5) 100%
    );
    border: solid 1px rgba(248, 82, 82, 1);
  }

  &-warning {
    background: linear-gradient(
      90deg,
      rgba(37, 37, 37, 1) 0%,
      rgba(255, 187, 0, 0.5) 100%
    );
    border: solid 1px rgba(255, 187, 0, 1);
  }

  &-info {
    background: linear-gradient(
      90deg,
      rgba(37, 37, 37, 1) 0%,
      rgba(0, 119, 255, 0.5) 100%
    );
    border: solid 1px rgba(0, 119, 255, 1);
  }

  &-success {
    background: linear-gradient(
      90deg,
      rgba(37, 37, 37, 1) 0%,
      rgba(0, 255, 8, 0.5) 100%
    );
    border: solid 1px rgba(0, 255, 8, 1);
  }

  &--is-snackbar {
    margin: 0;

    &#{$root}-error {
      background: linear-gradient(90deg, #252525 0%, rgba(90, 50, 50, 1) 100%);
    }

    &#{$root}-warning {
      background: linear-gradient(90deg, #252525 0%, rgba(102, 82, 25, 1) 100%);
    }

    &#{$root}-info {
      background: linear-gradient(90deg, #252525 0%, rgba(26, 62, 102, 1) 100%);
    }

    &#{$root}-success {
      background: linear-gradient(90deg, #252525 0%, rgba(26, 102, 28, 1) 100%);
    }
  }

  &__action {
    padding: 15px 15px;

    button {
      color: #fff;
    }
  }
}

// override notistack position
.SnackbarContainer-top {
  top: 100px !important;
}
