.timer {
  display: flex;
  justify-content: center;
  color: #66cc9a;
  font-size: 32px;
  font-family: Trivial, sans-serif;
  line-height: 32px;

  &__digit {
    min-width: 1.6em;
    text-align: center;
  }

  &__colon {
    margin: 0 5px;
  }

  &__description {
    text-align: center;
    font-size: 12px;
  }
}
