.unstake-confirmation-popup {
  display: block;
  text-align: center;

  &__title-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
  }

  &__title {
    font-weight: bold;
    font-size: 20px;
  }

  &__copy {
    margin-bottom: 15px;
  }

  &__footer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;

    button {
      margin: 0 10px;
    }
  }
}
