.balance-input {
  $root: &;

  &__label {
    background: #252525;
    border: 2px solid #66cc9a;
    box-shadow: 0 0 6px #66cc9a;
    border-radius: 16px;
    padding: 30px 20px;
    display: flex;
    align-items: center;
  }

  &__currency {
    min-width: fit-content;
    margin-left: auto;
    font-size: 14px;
    color: #878787;
    font-family: Trivial, sans-serif;
    font-weight: bold;
    display: flex;
    align-items: center;

    img,
    svg {
      height: 40px;
      width: 40px;
      margin-left: 10px;
    }
  }

  &__input {
    display: block;
    background: transparent;
    border: none;
    font-size: 21px;
    color: #fff;
    margin-left: 10px;
    font-family: Poppins, sans-serif;
    font-weight: bold;
    width: 100%;

    &:active,
    &:focus {
      outline: none;
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
  }

  &__balance {
    padding: 0 20px;
  }

  &__error-message {
    color: #f85252;
    padding: 0 20px;
    text-align: right;
  }

  &--condensed {
    #{$root}__label {
      padding: 5px 20px;
      box-shadow: none;
      border: 1px solid #5b5b5b;
    }

    #{$root}__currency {
      img,
      svg {
        height: 20px;
        width: 20px;
      }
    }
  }

  &--has-error {
    #{$root}__label {
      background: linear-gradient(90deg, #f8525215 0%, #25252515 100%);
      border-color: #f85252;
      box-shadow: 0 0 6px #f85252;
    }
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
